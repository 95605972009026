* {
  caret-color: transparent;
}

@media (min-width: 250px) {

  a {
    color:ivory;
  }

  a:visited {
    color: ivory;
  }

  .App {
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 99.7vw;
    overflow-x: hidden;
  }

  #mintButton {
    min-width: 10vw;
    min-height: 5vh;
    margin: 2vh;
    background-color: #fffcef;
    color: #1a1a1a;
    border: 2px solid #9ea5a5;
    cursor: pointer;
  }

  #mintButton:hover {
    min-width: 10vw;
    min-height: 5vh;
    margin: 2vh;
    background-color: #1a1a1a;
    color: #fffcef;
    border: 2px solid #9ea5a5;
    cursor: pointer;
  }

  #nft-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 12vw 0px;
    width: 90vw;

  }

  #walletButton {
    left: 90px;
    position: absolute;
    border-radius: 16px;
    text-align: right;
    margin-bottom: 30px;
    line-height: 16px;
    background-color: #1a1a1a;
    color: #fffcef;
    border: 2px solid #9ea5a5;
  }

  .nft {
    margin: 5px;
    max-width: 27vw;
    cursor:pointer;
  }

  .activeNft {
    box-shadow: 0px 0px 50px #fffcef;
    cursor: pointer;
    min-width: 60vw;
  }

  #highlight {
    width: 90vw;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }

  #highlight-left-container {
    text-align: center;
    height: 33vh;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }

  #highlight-text-container {
    margin-top: 2vh;
    padding: 0px 10px;
    border-radius: 16px;
    background-color: #9ea5a5;
    width: 80vw;

  }

  #mint-container {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #text-container {
    font-size: 5vh;
    max-width: 90vw;
    text-align: center;
  }

  .spacer {
  
  }

  #social-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  #social-icon {
    margin: 6px 0px;
    width: 5vh;
    height: 5vh;
    overflow: hidden;
    display: inline-flex;
  }

  #bigger {
    font-size:48px;
  }

  #os-icon {
    max-width: inherit;
    max-height: inherit;
    height: inherit;
    width: inherit;
    object-fit: cover;
  }

  #x {
    position: absolute;
    left: 25px;
    width: 50px;
  }

  #mint-counter {
    max-width: 25vw;
    right: 25px;
    position: absolute;
    visibility: visible;
  }

  #highlight-text {
    color: ivory;
    text-align: center;
  }

  #social {
    color: #fffcef;
    text-decoration: none;
  }

  #logoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #logo {
    max-width: 90vw;
    padding: 25px 25px 25px 25px;
  }

  #alien {
  }
  #dickbutt {
  }
  #mfer {
  }
  #moonbird {
  }
  #noun {
  }
  #toad {
  }

  button {
    padding: 9px 16px;
    max-height: 40px;
    border-color: #f8e897;
    color: #c5fbc3;
    background-color: white;
    border-radius: 8px;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    font-weight: bold;
  }

  #amount {
    font-size: 24px;
    padding: 9px 16px;
  }

  #mint-button-area {
    padding-top: 48px;
    text-align: center;
  }

  .small-text {
    font-size: 12px;
  }

  #price {
    font-size: 24px;
  }

  #title {
    padding-top: 32px;
  }

  #subtitle {
    text-align: center;
    max-width: 90vw;
    margin-bottom: 5vh;
  }

  #status {
    color:#ff0000;
  }

  #count {
    font-size: 48px;
    line-height: 16px;
  }

  #walletButton:hover {
    color: #ffffff;
    background: #9ea5a5;
    cursor: pointer;
  }

  #qmark {
    font-size: 35px;
    font-weight: 700;
    color: #b80808;
  }

}

@media (min-width: 1200px) {

  .App {
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 99.7vw;
    overflow-x: hidden;
  }

  #highlight-left-container {
    text-align: right;
    height: 33vh;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }

  #mint-button-area {
    padding-top: 0px;
    text-align: center;
  }

  #walletButton {
    left: 90px;
    position: fixed;
    border-radius: 16px;
    text-align: right;
    margin-bottom: 30px;
    line-height: 16px;
    background-color: #1a1a1a;
    color: white;
    border: 2px solid gray;
  }

  #nft-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 90vw;
    height: auto;
    padding: 2vw 0px;
  }

  * {

  }

  .nft {
    margin: 5px;
    max-width: 14.7vw;

  }

  .activeNft {
    margin-left: 5vw;
    box-shadow: 0px 0px 50px #fffcef;
    cursor: auto;
    min-width: 40vh;
    cursor:pointer;
    min-height: 33vh;
  }

  #highlight {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  #highlight-text-container {
    margin-top: 2vh;
    padding: 10px 5px;
    border-radius: 16px;
    background-color: #9ea5a5;
    max-width: 33vw;
  }

  #mint-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #x {
    position: fixed;
    width: 50px;
  }

  #mint-counter {
    right: 100px;
    position: fixed;
    visibility: visible;
  }

  #text-container {
    font-size: 5vh;
    max-width: 90vw;
    text-align: center;
  }
}
